<div class="card-container">
  <div class="left">
    <img src="/assets/photos/orion.jpeg" alt="" />
  </div>
  <div class="right">
    <h2>PERSONALITY</h2>
    <p>
      As the father and alpha of the pack, he’s the calmest & friendliest of the bunch. Because he’s older than his
      kids, his eye-sight has softened over time and will need more guidance when jumping onto the couch or walking in
      the backyard.
    </p>
    <mat-divider></mat-divider>
    <h2>Likes & Dislikes</h2>
    <p>
      He adores his little pink dinosaur that we call “Pinky” and loves to loaf-around. He loves belly rubs and will
      likely be the first to greet you.
    </p>
    <mat-divider></mat-divider>
    <h2>Extras:</h2>
    <p>
      He will walk towards the direction of his kibble and water, but might need some guidance. He eats at his own pace,
      He’s the friendliest of the herd and loves TLC.
    </p>
    <address>Age: 12.8 years Sex: Male Weight: 20 pounds Breed: Mini Schnauzer</address>
  </div>
</div>
