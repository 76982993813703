import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { OrionComponent } from './components/orion/orion.component';
import { QueenieComponent } from './components/queenie/queenie.component';
import { BasilComponent } from './components/basil/basil.component';
import { IkerComponent } from './components/iker/iker.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { NavMenuComponent } from './shared/nav-menu/nav-menu.component';
import { MaterialModule } from './material-module';

@NgModule({
  declarations: [
    AppComponent,
    OrionComponent,
    QueenieComponent,
    BasilComponent,
    IkerComponent,
    DashboardComponent,
    NavMenuComponent,
  ],
  imports: [BrowserModule, BrowserAnimationsModule, AppRoutingModule, MaterialModule],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
