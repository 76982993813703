<div class="card-container">
  <div class="left">
    <img src="/assets/photos/queenie.jpeg" alt="" />
  </div>
  <div class="right">
    <h2>PERSONALITY</h2>
    <p>
      She is the eldest of her siblings and is often the bossiest of the bunch. She loves the thrill of barking at
      anyone who passes by the house and has a lively, sassy personality.
    </p>
    <mat-divider></mat-divider>
    <h2>Likes & Dislikes</h2>
    <p>
      She thoroughly enjoys grabbing a bite of kibbles and running to her destination to spit out the kibbles onto the
      floor and start eating them one by one. This usually riles up her sister Basil and she too will join in the fun
      and start eating with her.
    </p>
    <mat-divider></mat-divider>
    <h2>Extras:</h2>
    <p>
      She might come across a toughie, which she is. But she melts when you use your sweet, baby talk voice and give her
      head scratches/TLC.
    </p>
    <address>Age: 11 Sex: Female (spayed) Weight: 14 pounds Breed: Mini Schnauzer</address>
  </div>
</div>
