<div class="card-container">
  <div class="left">
    <img src="/assets/photos/iker.jpeg" alt="" />
  </div>
  <div class="right">
    <h2>PERSONALITY</h2>
    <p>
      Ike follows his father (Orion) almost anywhere and everywhere his father walks by in his backyard. He has a
      tendency to bark and jump when everyone goes outside to do their business.
    </p>
    <mat-divider></mat-divider>
    <h2>Likes & Dislikes</h2>
    <p>
      He loves his treats and will occasionally swallow his treats whole without taking a few seconds to chew. Pro Tip:
      Try to give him one at a time. He does not like thunder, lightning, fireworks or loud noises. If he hears any one
      of those, he will likely try to hide in a closet for safety.
    </p>
    <mat-divider></mat-divider>
    <h2>Extras:</h2>
    <p>
      We left a thunder jacket hanging in the coat closet (next to the refrigerator). Feel free to wrap him in his
      thunder jacket should he begin to get nervous over the loud noises.
    </p>
    <address>Age: 10 years Sex: Male Weight: 18 pounds Breed: Mini Schnauzer</address>
  </div>
</div>
