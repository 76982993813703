import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { QueenieComponent } from './components/queenie/queenie.component';
import { BasilComponent } from './components/basil/basil.component';
import { IkerComponent } from './components/iker/iker.component';
import { OrionComponent } from './components/orion/orion.component';

const routes: Routes = [
  { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
  { path: 'dashboard', component: DashboardComponent },
  { path: 'orion', component: OrionComponent },
  { path: 'iker', component: IkerComponent },
  { path: 'basil', component: BasilComponent },
  { path: 'queenie', component: QueenieComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
