<div class="card-container">
  <div class="left">
    <img src="/assets/photos/basil.jpeg" alt="" />
  </div>
  <div class="right">
    <h2>PERSONALITY</h2>
    <p>
      She’s the first to run towards the backyard fence and bark at the neighbor's dog and the first to follow her
      sister’s kibble trail to eat dinner. Although she, like Ike and Queenie, is one of the three barkers in the
      family—she’s quite sweet and loves to give people kisses. She melts for TLC.
    </p>
    <mat-divider></mat-divider>
    <h2>Likes & Dislikes</h2>
    <p>
      She loves to eat when her sister Queenie has started to eat kibbles. You’ll likely see her running around to
      snatch up some kibble's from her sister, Queenie.
    </p>
    <mat-divider></mat-divider>
    <h2>Extras:</h2>
    <p>
      She LOVES baby talk / high-pitch happy voices. Basil recently had surgery to remove her kidney stones and is a
      little more sensitive in her abdomen area. No need to give her any belly rubs, but she would love a little head
      scratch here and there.
    </p>
    <address>Age: 10 years Sex: Female Weight: 16 pounds Breed: Mini Schnauzer</address>
  </div>
</div>
