<section class="page-content">
  <section class="grid">
    <article class="contacts">
      <a mat-stroked-button href="tel:1-956-744-4408">Call Tim!</a>
      <a mat-stroked-button href="tel:1-956-763-0911">Call Amanda!</a>
      <a mat-stroked-button href="tel:1-956-337-7737">Call Diana(pups gma)!</a>
      <a mat-stroked-button href="tel:1-562-236-8000">Call to PAZ East!</a>
      <a mat-stroked-button href="tel:1-562-892-9038">Call for pup emergency vet!</a>
    </article>
    <article class="do">
      <h3>Please Do:</h3>
      <mat-list>
        <mat-list-item>- Change their water regularly.</mat-list-item>
        <mat-list-item>
          - Let them out to do business every 2-3hrs. Even if for only 5min. They never wanna be out much longer than
          that anyways.
        </mat-list-item>
        <mat-list-item>- Do remove the kibbles from the floor after 7pm. </mat-list-item>
        <mat-list-item>- Let Orion sleep & hangout in the living room when you leave the house.</mat-list-item>
        <mat-list-item>- Keep the pups in the black pen for them to sleep overnight.</mat-list-item>
        <mat-list-item>- Do sleep in the second bedroom upstairs.</mat-list-item>
        <mat-list-item>- Do log into our WiFi and/or Apple TV, YouTube TV, and other streaming services.</mat-list-item>
      </mat-list>
    </article>
    <article class="dont">
      <h3>Please Do Not:</h3>
      <mat-list>
        <mat-list-item>- No table food. Their designated treats only.</mat-list-item>
        <mat-list-item>- No need to pick up their business in our backyard.</mat-list-item>
        <mat-list-item>
          - Don't let them get to riled up with neighbors dogs if pups are in the backyard; specifically Orion.
        </mat-list-item>
        <mat-list-item>- Don't cook.</mat-list-item>
        <mat-list-item>
          - Please keep pups in the living room area, they are not allowed upstairs or in the front yard
        </mat-list-item>
        <mat-list-item>- Please no guests whatsoever.</mat-list-item>
        <mat-list-item>- Please don't bring any other pets.</mat-list-item>
        <mat-list-item>
          - While we don't want to enforce a curfew, I don't want the pups to get to excited when coming and going after
          dark.
        </mat-list-item>
      </mat-list>
    </article>
    <article>
      <h2>General tidbits:</h2>
      <mat-list>
        <mat-list-item>- The property has cameras outside that record 24/7.</mat-list-item>
        <mat-list-item>- We also have a camera in the kitchen/living room that also records 24/7.</mat-list-item>
        <mat-list-item
          >- You can ask google to turn on backyard lights by saying "Hey google turn on the backyard lights" from the
          kitchen, so the pups can see better at night.
        </mat-list-item>
        <mat-list-item>- The house does have an alarm system, but we'll keep it off for the most part.</mat-list-item>
        <mat-list-item>- Feel free to park on the drive way.</mat-list-item>
      </mat-list>
    </article>
  </section>
</section>
